import * as React from "react"
import {Link} from "gatsby"

export default function NotFoundPage() {
    return (
        <div>
            <button type="primary"><Link to={"/"}>Back Home</Link></button>
        </div>
    )
}
